<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SERVICE.WAREHOUSE_ITEMS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['service-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder ml-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend> </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingServiceWarehouseItem"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['service-update'])"
              :to="{ name: 'edit-service-warehouse-items', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";

import { FETCH_SERVICE_WAREHOUSE_ITEMS } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      gasStations: [],
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100
      },
      filters: {},
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        { text: this.$i18n.t("FORM_LABELS.NAME"), value: "name" },
        { text: this.$i18n.t("FORM_LABELS.WAREHOUSE"), value: "warehouse" },
        { text: this.$i18n.t("FORM_LABELS.AVG_PRICE"), value: "avgPrice" },
        {
          text: this.$i18n.t("FORM_LABELS.AVAILABLE"),
          value: "available"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SERVICE.WAREHOUSE_ITEMS"),
        route: { name: "list-service-warehouse-items" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
  },
  computed: {
    ...mapGetters(["isLoadingServiceWarehouseItem"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      return this.headers;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_SERVICE_WAREHOUSE_ITEMS, apiParams)
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-service-warehouse-items" });
          }
        });
    }
  }
};
</script>
